// スマホだと2回タップしないとリンク先に飛ばない問題の対策
window.ontouchstart = function() {};
window.addEventListener('touchstart', function() {}, true);
window.addEventListener('touchstart', function() {}, false);

// 年数（コピーライト）
$(function(){
  var year = new Date().getFullYear();
  $("#year").html(year);
});

// ヘッダーメニュー
$(function() {
  var state = false;
  var pos;

  $(".header-menu-btn").on("click", function() {
    if (state == false) {
      pos = $(window).scrollTop();
      $('body').addClass('is-fixed').css({'top': -pos});
      state = true;
    } else {
      $('body').removeClass('is-fixed').css({'top': 0});
      window.scrollTo(0, pos);
      state = false;
    }
    $(this).toggleClass('active');
    $('.header-nav, .header-layer').toggleClass('active');
  });

  // 領域外をクリック時
  $('.header-layer, .header-nav-list a, .header-contact-btn').on("click", function() {
    $('.header-menu-btn').removeClass('active');
    $('.header-nav, .header-layer').removeClass('active');
    $('body').removeClass('is-fixed').css({'top': 0});
    window.scrollTo(0, pos);
    state = false;
  });
});

var unit = 100,
    canvasList, 
    info = {}, 
    colorList;

function init() {
    info.seconds = 0;
    info.t1 = 0; // 波1の時間
    info.t2 = 0; // 波2の時間
    canvasList = [];
    colorList = [];

    canvasList.push(document.getElementById("waveCanvas"));
    colorList.push(['#EBFBFF', '#D3F6FF']); 

    for(var canvasIndex in canvasList) {
        var canvas = canvasList[canvasIndex];
        canvas.width = document.documentElement.clientWidth;
        canvas.height = 200;
        canvas.contextCache = canvas.getContext("2d");
    }

    update();
}

function update() {
    info.seconds += 0.0005;
    info.t1 = info.seconds * Math.PI; // 波1の時間更新
    info.t2 = info.seconds * 5 * Math.PI; // 波2の時間更新（速くしたい場合）

    for(var canvasIndex in canvasList) {
        var canvas = canvasList[canvasIndex];
        draw(canvas, colorList[canvasIndex]);
    }
    setTimeout(update, 35);
}

function draw(canvas, color) {
    var context = canvas.contextCache;
    context.clearRect(0, 0, canvas.width, canvas.height);

    drawWave(canvas, color[0], 1, 3, 0, info.t1); // 波1
    drawWave(canvas, color[1], 1, 1.8, 250, info.t2); // 波2（速く）
}

function drawWave(canvas, color, alpha, zoom, delay, t) {
    var context = canvas.contextCache;
    context.fillStyle = color;
    context.globalAlpha = alpha;
    context.beginPath();
    drawSine(canvas, t / 0.5, zoom, delay);
    context.lineTo(canvas.width + 10, canvas.height);
    context.lineTo(0, canvas.height);
    context.closePath();
    context.fill();
}

function drawSine(canvas, t, zoom, delay) {
    var xAxis = Math.floor(canvas.height / 2);
    var yAxis = 0;
    var context = canvas.contextCache;
    var x = t;
    var y = Math.sin(x) / zoom;
    context.moveTo(yAxis, unit * y + xAxis);

    for (i = yAxis; i <= canvas.width + 10; i += 10) {
        x = t + (-yAxis + i) / unit / zoom;
        y = Math.sin(x - delay) / 3;
        context.lineTo(i, unit * y + xAxis);
    }
}

init();
